import React from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { Link } from "gatsby"

import FacebookIcon from "@material-ui/icons/Facebook"
import InstagramIcon from "@material-ui/icons/Instagram"
import LinkedInIcon from "@material-ui/icons/LinkedIn"
import PhoneIphone from '@material-ui/icons/PhoneIphone'
import EmailIcon from '@material-ui/icons/Email'
import LocationOn from "@material-ui/icons/LocationOn"

import { contact } from '../data/static-data';
import theme from "./theme"
import Fnaim1 from '../../images/fnaimv.png'
import Interkab1 from '../../images/interkabv.png'
import Ymo from "../../images/Ymo.png"

const useStyles = makeStyles(theme => ({
    footContainer: {
        height: "auto",
        backgroundColor: theme.palette.primary.main,
        paddingTop: "1.5rem",
        paddingBottom: "1.5rem",
        paddingLeft: "1.5rem",
        paddingRight: "1.5rem"
    },
    offerLine: {
        height: "4.5rem",
        borderLeft: `2px solid ${theme.palette.common.white}`,
        paddingLeft: "1rem",
        marginRight: "5rem",
        [theme.breakpoints.down('xs')]: {
            borderLeft: "0px",
        },
        [theme.breakpoints.down('sm')]: {
            borderLeft: "0px",
            paddingLeft: "0",
        }
    },
    contactLine: {
        borderLeft: `2px solid ${theme.palette.common.white}`,
        paddingLeft: "1rem",
        [theme.breakpoints.down('xs')]: {
            borderLeft: "0px",
        },
        [theme.breakpoints.down('sm')]: {
            borderLeft: "0px",
        }
    },
    centerContainer: {
        width: "42rem",
        borderBottom: `2px solid ${theme.palette.common.white}`,
        paddingBottom: "3rem",
        [theme.breakpoints.down('xs')]: {
            borderBottom: "0px",
            width: "auto"
        },
        [theme.breakpoints.down('sm')]: {
            borderBottom: "0px",
            width: "16rem",

        }
    },
    whiteColor: {

    },
    socialContainer: {
        width: "100%",
        [theme.breakpoints.down('xs')]: {
            width: "100%"
        }
    },
    logoImg: {
        marginLeft: "2rem",
        width: "5rem",
        height: "5rem"
    },
    partenaireLogo: {
        width: '5rem',
        height: '3.5rem'
    },
    partenaireContainer: {
        width: '16rem',
        height: '8rem'
    },
    fnaimContainer: {
        backgroundColor: '#fff',
        width: '7rem',
        padding: '1rem'
    },
    immoLogContainer: {
        width: '16rem',
        height: '13rem'
    },
    politicsContainer: {
        height: '10rem'
    },
    mentionsContainer: {
        width: "9rem",
        height: "4rem"
    },
    ymo: {
        width: "12rem"
    }
}))





export default function Footer() {
    const classes = useStyles()

    const matchesXS = useMediaQuery((theme) => theme.breakpoints.down("xs"))
    const matchesSM = useMediaQuery((theme) => theme.breakpoints.down("sm"))

    const contactItems = [
        { icon: <PhoneIphone style={{ fill: theme.palette.common.white }} />, id: 'phone', label: `${contact.phone}` },
        { icon: <EmailIcon style={{ fill: theme.palette.common.white }} />, id: 'email', label: `${contact.email}` },
        { icon: <LocationOn style={{ fill: theme.palette.common.white }} />, id: 'adresse', label: '6 rue Porte Neuve 44000 Nantes' },
    ]

    const socialItems = [
        { id: 'linkedin', icon: <LinkedInIcon style={{ fill: theme.palette.common.white }} /> },
        { id: 'instagramm', icon: <InstagramIcon style={{ fill: theme.palette.common.white }} /> },
        { id: 'facebook', icon: <FacebookIcon style={{ fill: theme.palette.common.white }} /> },
    ]


    return (
        <Grid item container
            classes={{ root: classes.footContainer }}
            justifyContent="space-between"
            alignItems="center"
            direction={(matchesXS || matchesSM) ? "column" : "row"}>
            <Grid item container justifyContent="space-between" classes={{ root: classes.immoLogContainer }} direction="column">
                <Grid item container alignItems="center" justifyContent="space-between" alignContent="center" classes={{ root: classes.partenaireContainer }} direction="column">
                    <Grid item>
                        <Typography style={{ color: theme.palette.common.white }} variant="body2">ADHÉRENTS</Typography>
                    </Grid>
                    <Grid item container justifyContent="space-between" direction="row">
                        <Grid item classes={{ root: classes.fnaimContainer }}>
                            <img className={classes.partenaireLogo} src={Fnaim1} alt="Logo de FNAIM" />
                        </Grid>
                        <Grid item classes={{ root: classes.fnaimContainer }}>
                            <img className={classes.partenaireLogo} src={Interkab1} alt="Logo de interkab" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Typography component={Link} to="/mentions" style={{ color: theme.palette.common.white, textDecoration: 'none', marginRight: '1rem' }}>Mentions légales</Typography>
                </Grid>
                <Grid item style={{margin: '0 1rem 2rem 0'}}>
                    <Typography component={Link} to="/honoraires" style={{ color: theme.palette.common.white, textDecoration: 'none' }}>Honoraires</Typography>
                </Grid>
            </Grid>
            <Grid item>
                <div className={classes.centerContainer}>
                    <Grid item container alignItems={matchesXS ? "flex-start" : "center"}
                        alignContent={matchesXS ? "flex-start" : "center"}
                        justifyContent="center"
                        direction={(matchesXS || matchesSM) ? "column" : "row"}>
                        <Grid item >
                            <div className={classes.offerLine}>
                                <Grid item container classes={{ root: classes.offfLine }} alignContent="flex-start" direction="column">
                                    <Grid item>
                                        <Typography component={Link} to="/" style={{ color: theme.palette.common.white, textDecoration: "none" }}>Notre Offre</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography component={Link} to="/estimation" style={{ color: theme.palette.common.white, textDecoration: "none" }}>Estimer un bien</Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item>
                            <div className={classes.contactLine}>
                                <Grid item container direction="column">
                                    {
                                        contactItems.map(item => (
                                            <Grid item container alignItems="center" direction="row" key={item.id}>
                                                <Grid item style={{ margin: '0.5rem' }}>
                                                    {item.icon}
                                                </Grid>
                                                <Grid item>
                                                    <Typography style={{ color: theme.palette.common.white }}>{item.label}</Typography>
                                                </Grid>
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item>
                            <img className={classes.ymo} src={Ymo} />
                        </Grid>
                    </Grid>
                </div>
            </Grid>
            <Grid item>
                <Grid item container classes={{ root: classes.socialContainer }} alignItems="center" direction={(matchesXS || matchesSM) ? "row" : "column"}>
                    {socialItems.map(mItem => (
                        <Grid item key={mItem.id}>
                            {mItem.icon}
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    )
}