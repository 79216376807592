import React, { useState } from "react"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Button from "@material-ui/core/Button"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import { makeStyles } from "@material-ui/styles"
import MenuIcon from "@material-ui/icons/Menu"
import { Link } from "gatsby"
import { contact } from '../../data/static-data'
import Profile from "@material-ui/icons/Person"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { IconButton, List, ListItem, ListItemText, SwipeableDrawer } from "@material-ui/core"

import CustomizedMenus from "./customizedMenu"
import theme from "../theme"

// import Logo1 from "../../../images/logo1.jpeg"
import Logo from "../../../images/logo1.jpeg"



const useStyles = makeStyles(theme => ({
    appBarContain: {
        width: "100%",
        height: "7rem",
        margin: 0,
    },
    tabs: {
        /* marginRight: 'auto', */
        marginLeft: 'auto'
    },
    listItemText: {
        color: theme.palette.common.black
    },
    menuAmbergur: {
        position: "absolute",
        right: 0
    },
    trait: {
        height: "2px",
        width: "100%",
        backgroundColor: theme.palette.secondary.main
    },
    logo: {
        width: "4rem",
        height: "4rem",
        marginLeft: "2rem"
    }
}))

export default function Header() {
    const classes = useStyles()

    const matchesMD = useMediaQuery((theme) => theme.breakpoints.down('md'))

    const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent)

    const [drawerOpen, setDrawerOpen] = useState(false)

    const tabItems = [
        { id: 'accueil', label: 'Accueil', to: '/', visible: true },
        { id: 'annonces', label: 'Annonces', to: '/annonces', visible: true },
        { id: 'offers', label: 'Notre offre', to: '/offre', visible: true },
        { id: 'contact', label: 'Contactez-nous', to: '/contact', visible: true },
        // { id: 'phone', icon: <CallIcon />, label: contact.phone, visible: true},
        { id: 'profile', icon: <Profile />, label: 'Se connecter', to: '/', visible: false }
    ]


    const headItems = [
        { title: "Accueil", link: '/' },
        { title: 'Annonces', link: '/annonces' },
        { title: "Notre offre", link: '/offre' },
        { contact: "Contactez-nous", link: "/contact"}
        // { title: contact.phone, link: '/' },
        // { title: "Acheter un bien", link: '/' },
        // { title: "Vendre un bien", link: '/' }
    ]


    const activeInex = () => {
        const found = tabItems.indexOf(tabItems.filter(({ to }) => (`${to}`) === window.location.pathname)[0])
        return found === -1 ? false : found
    }



    const tabs = (
        <Tabs classes={{ root: classes.tabs }} value={activeInex}>
            {tabItems.map(mItem => (
                mItem.visible && <Tab key={mItem.id} component={Link} to={mItem.to} label={mItem.label} />
            ))}
        </Tabs>
    )

    const drawer = (
        <SwipeableDrawer
            open={drawerOpen}
            onOpen={() => setDrawerOpen(true)}
            onClose={() => setDrawerOpen(false)}
            disableBackdropTransition={!iOS}
            disableDiscovery={iOS}>
            <List disablePadding>
                {
                    headItems.map((headitem, i) => (
                        <ListItem 
                        selected={activeInex}
                        divider 
                        button 
                        component={headitem.link ? Link : undefined} 
                        to={headitem.link ? headitem.link : undefined} 
                        key={headitem.title}>
                            <ListItemText classes={{ primary: classes.listItemText }} primary={headitem.title} />
                        </ListItem>
                    ))
                }
            </List>
        </SwipeableDrawer>
    )

    return (
        <AppBar elevation={0} position="fixed" >
            <Toolbar disableGutters>
                <Button component={Link} to="/">
                    <img src={Logo} className={classes.logo} alt="Logo1 immo-defi" />
                </Button>
                {matchesMD ? drawer : tabs}
                {/* {!matchesMD && <CustomizedMenus />} */}
                {matchesMD && (
                    <IconButton classes={{ root: classes.menuAmbergur }} onClick={() => setDrawerOpen(true)}>
                        <MenuIcon style={{ fill: "#fff" }} />
                    </IconButton>)}
            </Toolbar>
            <div className={classes.trait}></div>
        </AppBar>
    )
}