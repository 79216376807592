import React from "react"
import { makeStyles } from "@material-ui/styles"
import { Grid } from '@material-ui/core'
import CookieConsent from "react-cookie-consent"
import Header from "./header/header"
import Footer from "./footer"

const useStyles = makeStyles(theme => ({
    spacer: {
        marginBottom: '5rem',
    },
    containLayout: {
        width: "100%",
        margin: "0 0 0 0"
    }
}))

const Layout = ({ children }) => {
    const classes = useStyles()

    return (
            <Grid container >
                <Grid item xs={12} >
                    <Header />
                    <main>{children}</main>
                    <div className={classes.spacer} />

                    <CookieConsent
          enableDeclineButton
          declineButtonText="Je refuse"
          location="bottom"
          buttonText="C'est ok pour moi"
          cookieName="cookiesPerf"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          expires={150}
        >
          Ce site web utilise des cookies pour améliorer l'expérience de l'utilisateur.{" "}
          <span style={{ fontSize: "10px" }}>Que vous pouvez refuser</span>
        </CookieConsent>
                    <Footer />
                </Grid>
            </Grid>
    )
}
export default Layout